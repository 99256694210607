import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
    HttpSentEvent,
    HttpHeaderResponse,
    HttpProgressEvent,
    HttpResponse,
    HttpUserEvent
} from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { switchMap, take, filter, catchError, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../src/environments/environment';
declare var window: any;

@Injectable()

export class TokenInterceptor implements HttpInterceptor {

    isRefreshingToken: boolean = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    // envConfig = environment;
    clientSecret = environment.ClientSecret;

    constructor(
        public authService: AuthService,
        private router: Router
    ) { }

    /**
     * intercept
     * modify http header request
     * @param request http request
     * @param next go to next
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent
        | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {

        return next.handle(this.addTokenToRequest(request, this.authService.getJwtToken()))
            .pipe(
                catchError(err => {
                    if (err instanceof HttpErrorResponse) {
                        switch ((<HttpErrorResponse>err).status) {
                            case 401:
                                return this.handle401Error(request, next);
                            case 501:
                                return this.handle401Error(request, next);
                            case 410:
                                this.authService.removeTokens();
                                return this.router.navigate(['/']);
                            case 417:
                                this.authService.removeTokens();
                                return this.router.navigate(['/']);
                            case 502:
                                this.authService.removeTokens();
                                return this.router.navigate(['/']);
                            default:
                                return throwError(err);
                        }
                    } else {
                        return throwError(err);
                    }
                }));
    }

    /**
     * handle401Error
     * handle refresh token
     * @param request http request
     * @param next go to next
     */
    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {

        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;
            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            return this.authService.refreshToken()
                .pipe(
                    switchMap((token) => {
                        if (token) {
                            this.tokenSubject.next(token.data.accessToken);
                            return next.handle(this.addTokenToRequest(request, token.data.accessToken));
                        }
                        this.authService.removeTokens();
                        return this.router.navigate(['/']);
                    }),
                    catchError(err => {
                        this.authService.removeTokens();
                        return this.router.navigate(['/']);
                    }),
                    finalize(() => {
                        this.isRefreshingToken = false;
                    })
                );
        } else {
            this.isRefreshingToken = false;

            return this.tokenSubject
                .pipe(filter(token => token != null),
                    take(1),
                    switchMap(token => {
                        return next.handle(this.addTokenToRequest(request, token));
                    }));
        }
    }


    /**
     * addToken
     * @param request  http request
     * @param token request token
     */
    private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
        const tokenType = this.getTokenType(request);
        if (tokenType === 'clientSecret') {
            return request.clone({
                setHeaders: {
                    client_secret: `${this.clientSecret}`,
                    'zone-offset': (-1 * ((new Date()).getTimezoneOffset())).toString()
                }
            });
        } else if (tokenType === 'token') {
            return request.clone({
                setHeaders: {
                    access_token: `${token}`,
                    'zone-offset': (-1 * ((new Date()).getTimezoneOffset())).toString()
                }
            });
        }
    }

    /**
     * getTokenType
     * check token type
     */
    private getTokenType = (request: HttpRequest<any>) => {
        if (request.url.indexOf('login') > 0
            || request.url.indexOf('check-user') > 0
            || request.url.indexOf('register') > 0
            || request.url.indexOf('email-verification') > 0
            || request.url.indexOf('forgot-password') > 0
            || request.url.indexOf('verify-otp') > 0
            || request.url.indexOf('otplink-verify') > 0
            || request.url.indexOf('reset-password') > 0
            || request.url.indexOf('access-token') > 0) {
            return 'clientSecret';
        } else {
            return 'token';
        }
    }



}
