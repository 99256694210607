// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
    // baseUrl: 'http://localhost:3000/admn/api/v1',
  //baseUrl:'https://patelliquor-dev.azurewebsites.net/admn/api/v1',//'https://patelliquor-dev.azurewebsites.net/admn/api/v1', --use
  baseUrl: 'https://patelprod-new.azurewebsites.net/admn/api/v1',
  //baseUrl:'http://10.11.7.240:3000/admn/api/v1',
  // baseUrl: 'https://admin-management.azurewebsites.net/admn/api/v1',
  

  //baseUrl : 'https://patel-liquor-prod.azurewebsites.net/admn/api/v1', --use
  ClientSecret: 'O6tkqeExC5df9cLSQXFML3sC66G2HWbnftLs7JA6z7n4ziFOvR3I88UC2y1VF25g8ctvIY55gL01BHut8pF0grdr4YvZloNaPILOLgKi66V65BnbtM09a2ufgy5d1KcS'
};

/*ng
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/disip  
