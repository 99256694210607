import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundErrorComponent } from './page-not-found-error/page-not-found-error.component';



const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: '/',
  //   pathMatch: 'full',
  // },
  {
    path: '',
    loadChildren: () => import('./admin/auth/auth.module').then(mod => mod.AuthModule)
  },
  {
    path: '',
    loadChildren: () => import('./admin/admin.module').then(mod => mod.AdminModule)
  },
  {
    path: '404', component: PageNotFoundErrorComponent
  },

  // {
  //   path: '**',
  //   redirectTo: '/'
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
