import { Injectable } from '@angular/core';
import { EncryptionService } from '../encryption-service/encryption.service';
import { AuthService } from '../auth/auth.service';

import { NgxPermissionsService } from 'ngx-permissions';
 
@Injectable({
 providedIn: 'root'
})
export class PermissionService {
    // private onRefreshLoadPermission = new BehaviorSubject(null);
    // public setPermission = this.onRefreshLoadPermission.asObservable();
 
 constructor(
 private encrptionService: EncryptionService,
 private ngxPermissionsService: NgxPermissionsService,
 private authService: AuthService
 ) { }
 
 getUserRole() {
        const userRole = this.encrptionService.decryptUsingAES256(localStorage.getItem(this.authService.USER_ROLE));
        const array = userRole.split(',');
        return array;
     

 }

//  loadPermission(data) {
// if(data){
//     this.onRefreshLoadPermission.next(data);
// }
   
//   }

 
}
