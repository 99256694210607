import { Injectable, Injector } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import Fingerprint2 from 'fingerprintjs2';
import { EncryptionService } from '../encryption-service/encryption.service';
import { PermissionService } from '../role-permission/permission.service';
import { NgxPermissionsService } from 'ngx-permissions';

declare var window: any;

@Injectable({
  providedIn: 'root'
})

export class AppInitService {
  constructor(
    private http: HttpClient,
    private injector: Injector,
    private ngxPermissionsService: NgxPermissionsService,
    private permissionService: PermissionService,
    private encrptionService: EncryptionService,
  ) { }
  // Validators.pattern(/^[0-9]+(.[0-9]{0,2})?$/)
  public init() {
    return new Promise((resolve, reject) => {
      Fingerprint2.getV18((result, components) => {
        window.config = {
          emailPattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,///^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
          phoneNumber: /^(\+\d{1,3}[- ]?)?\d{10}$/,
          paginationMaxSize: 5,
          paginationDirectionLinks: true,
          paginationAutoHide: false,
          paginationResponsive: true,
          itemsPerPage: 10,
          currentPage: 1,
          deviceId: result,
          directoryBaseURL: 'https://patelproblob.blob.core.windows.net',
          patientDir: 'patient',
          partelAdminDir: 'patelmedia',
          clinicDir: 'clinic',
          baseUrl: environment.baseUrl,
          azStorageAccount: 'patelproblob',
          azStorageDirectory: 'files',
          azStorageDirectoryUserImage: 'user-image',
          azStorageDirectoryLicence: 'licence',
          azStorageDirectoryProduct: 'product',
          azContainerName: 'patelmedia',
          AesSecretKey: '08277A08B0ABA70703E28A5FCED7396D',
          AesSecretIVKey: 'D9062EA86462F77E',
          ClientSecret: 'O6tkqeExC5df9cLSQXFML3sC66G2HWbnftLs7JA6z7n4ziFOvR3I88UC2y1VF25g8ctvIY55gL01BHut8pF0grdr4YvZloNaPILOLgKi66V65BnbtM09a2ufgy5d1KcS'
        };
        this.load()
      });
     
      resolve(true);
      
    });
  }
   load(){
        const roles = this.permissionService.getUserRole();
        this.ngxPermissionsService.loadPermissions(roles); 
  }

}
