import { Injectable } from '@angular/core';
import { DispatcherService } from 'src/app/service-dispatcher/dispatcher.service';
import { tap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { EncryptionService } from '../encryption-service/encryption.service';
import { Observable } from 'rxjs';
import * as jwt_decode from "jwt-decode";
import { environment } from '../../../environments/environment';
import { CompileShallowModuleMetadata } from '@angular/compiler';
import { NgxPermissionsService } from 'ngx-permissions';

declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // envConfig = window.config;
  // baseUrl = environment.baseUrl;

  private readonly JWT_TOKEN = 'token';
  private readonly REFRESH_TOKEN = 'refreshToken';
  private readonly USER_ID = 'userId';
  private readonly EMAIL_ID = 'email';
  public readonly USER_ROLE = 'roleName';

  constructor(
    private dispatcherService: DispatcherService,
    private encrptionService: EncryptionService,
    private http: HttpClient,
    private ngxPermissionsService: NgxPermissionsService,



  ) { }

  /**
   * login
   * admin login
   * @param request
   */
  login(request) {
    return new Promise((resolve, reject) => {
      this.dispatcherService.post(`${environment.baseUrl}/login`, request).subscribe((res: any) => {
        if (res.status) {
          resolve(res);
        } else {
          reject(res);
        }
      }, (err) => {
        reject(err);
      });
    });
  }

  /**
   * refreshToken
   * refresh Token
   */
  refreshToken(): Observable<any> {
    let decodedToken = this.getDecodedAccessToken(localStorage.getItem(this.JWT_TOKEN))
    return this.http.post<any>(`${environment.baseUrl}/access-token`, {
      refreshToken: this.getRefreshToken(),
      userId: decodedToken.userId,
      deviceId: window.config.deviceId
    }).pipe(
      map(tokens => {
        if (tokens && tokens.data.accessToken) {
          this.storeJwtToken(tokens.data.accessToken);
        }
        return tokens;
      }));
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }


  /**
   * verifyToken
   * @param token
   */
  verifyToken(token) {
    // return new Promise((resolve, reject) => {
    //   this.dispatcherService.get(`${environment.baseUrl}/verifyLink`, { verificationToken: token }).subscribe((res: any) => {
    //     resolve(res);
    //   }, (err) => {
    //     reject(err);
    //   });
    // });
  }

  /**
   * forgetPassword
   * forgot password
   * @param request
   */
  forgetPassword(request) {
    return new Promise((resolve, reject) => {
      this.dispatcherService.post(`${environment.baseUrl}/forgot-password`, request).subscribe((res: any) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  /**
   * resetPassword
   * reset password
   * @param request
   */
  resetPassword(request) {
    return new Promise((resolve, reject) => {
      this.dispatcherService.put(`${environment.baseUrl}/reset-password`, request).subscribe((res: any) => {

        if (res.status) {
          resolve(res);
        } else {
          reject(res);
        }
      }, (err) => {
        reject(err);
      });
    });
  }

  /**
   * verifyForgotPasswordLink
   * verify forgot password link
   * @param guid
   */
  verifyForgotPasswordLink(guid) {
    return new Promise((resolve, reject) => {
      this.dispatcherService.post(`${environment.baseUrl}/verify-forgot-password-link`, guid).subscribe((res: any) => {
        if (res.status) {
          resolve(res);
        } else {
          reject(res);
        }
      }, (err) => {
        reject(err);
      });
    });
  }

  /**
 * verifyOtpLink
 * verify OTP link
 * @param params
 */
  verifyOtpLink(params) {
    return new Promise((resolve, reject) => {
      this.dispatcherService.post(`${environment.baseUrl}/otplink-verify`, params).subscribe((res: any) => {
        if (res.status) {
          resolve(res);
        } else {
          reject(res);
        }
      }, (err) => {
        reject(err);
      });
    });
  }

  /**
   * verifyUserEmailLink
   * description : user for app user verify link
   * @param params
   */
  verifyUserEmailLink(request) {
    return new Promise((resolve, reject) => {
      this.dispatcherService.get(`${environment.baseUrl}/email-verification`, request).subscribe((res: any) => {
        if (res.status) {
          resolve(res);
        } else {
          reject(res);
        }
      }, (err) => {
        reject(err);
      });
    });
  }

  /**
   * verifyUserResetPasswordLink
   * description : verify User Reset Password Link
   * @param params
   */
  verifyUserResetPasswordLink(params) {
    // return new Promise((resolve, reject) => {
    //   this.dispatcherService.post(`${environment.baseUrl}/verify-forget-password-link`, params).subscribe((res: any) => {
    //     if (res.status) {
    //       resolve(res);
    //     } else {
    //       reject(res);
    //     }
    //   }, (err) => {
    //     reject(err);
    //   });
    // });
  }

  /**
   * resetUserPassword
   * description : Reset User Password
   * @param params
   */
  resetUserPassword(params) {
    // return new Promise((resolve, reject) => {
    //   this.dispatcherService.post(`${environment.baseUrl}/set-password`, params).subscribe((res: any) => {
    //     if (res.status) {
    //       resolve(res);
    //     } else {
    //       reject(res);
    //     }
    //   }, err => {
    //     reject(err);
    //   });
    // });
  }


  private getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  private getUserId() {
    return this.encrptionService.decryptUsingAES256(localStorage.getItem(this.USER_ID));
  }

  private storeJwtToken(jwt: string) {
    localStorage.setItem(this.JWT_TOKEN, jwt);
  this.storeUserInfoAfterRefresh(jwt)
  }



  public storeUserInfo(data) {
    const tokenUser = this.getDecodedAccessToken(data.accessToken);
    localStorage.setItem(this.JWT_TOKEN, data.accessToken);
    localStorage.setItem(this.REFRESH_TOKEN, data.refreshToken);
    localStorage.setItem(this.EMAIL_ID, this.encrptionService.encryptUsingAES256(data.emailId))
    localStorage.setItem(this.USER_ID, this.encrptionService.encryptUsingAES256(data.userId));
    localStorage.setItem(this.USER_ROLE, this.encrptionService.encryptUsingAES256(tokenUser.roleName));

  }

  private storeUserInfoAfterRefresh(data) {
    const tokenUser = this.getDecodedAccessToken(data);
    localStorage.setItem(this.USER_ROLE, this.encrptionService.encryptUsingAES256(tokenUser.roleName));
    this.encrptionService.loadPermission(data)
    // const permission = tokenUser.roleName.split(',');
    // this.ngxPermissionsService.loadPermissions(permission); 

  }

  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  public removeTokens() {
    // localStorage.removeItem(this.USER_ID);
    // localStorage.removeItem(this.EMAIL_ID);
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
    localStorage.removeItem(this.USER_ROLE);
  }

}
