import { ToastMessage } from './../toast/toast';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class DispatcherService {

  constructor(private http: HttpClient) { }

  /**
   * get method
   * @param url api url
   * @param data api data
   * @param header api header
   */
  get(url, data = {}, header = {}): Observable<any> {
    return this.http.get(url, { headers: header, params: data }).pipe(
      catchError(this.handleError));
  }

  /**
   * post method
   * @param url api url
   * @param data api data
   * @param headers api header
   */
  post(url, data, headers = {}) {

    return this.http.post(url, data, headers).pipe(
      catchError(this.handleError));
  }

  /**
   * put method
   * @param url api url
   * @param data api data
   * @param headers api header
   */
  put(url, data, headers = {}) {
    return this.http.put(url, data, headers).pipe(
      catchError(this.handleError));
  }

  /**
   * delete method
   * @param url api url
   * @param data api data
   */
  delete(url, data = {}) {
    return this.http.delete(url, { params: data }).pipe(
      catchError(this.handleError));
  }

  // Error 
  handleError(error: HttpErrorResponse) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;
    } else {
      // server-side error
      if(error?.error?.data && error?.error?.data?.errors){
        return throwError(error?.error);
      }
      msg = error?.error?.message ? error.error.message : (`Error Code: ${error.status}\nMessage: ${error.message}`);
    }
    ToastMessage.fire({ title: msg, icon: 'error' });

    return throwError(msg);
  }

}
